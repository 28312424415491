import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../utils/firebase.js";
import { Table, Backdrop, CircularProgress, TableBody } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";

const DriversList = () => {
  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const getData = async () => {
      const driverSnap = getDocs(collection(db, "drivers"));
      try {
        setDrivers(
          (await driverSnap).docs.map((doc) => ({ ...doc.data(), id: doc.id })),
        );
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    };

    getData();
  }, []);

  const clickRow = (row) => {
    navigate(`/driver/${row.id}`, { state: { id: row.id } });
  };

  const renderData = (row) => {
    // console.log(row)
    return (
      <TableRow
        key={row.id}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          "&.MuiTableRow-hover": { cursor: "pointer" },
        }}
        onClick={() => clickRow(row)}
        hover={true}
      >
        <TableCell>{row.id}</TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell>{row.name_ch}</TableCell>
        <TableCell>{row.phone_number}</TableCell>
      </TableRow>
    );
  };

  return (
    <div className="admin-right">
      <h2>Driver</h2>
      {loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="Driver table">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Chinese Name</TableCell>
                <TableCell>Phone No.</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{drivers.map((data) => renderData(data))}</TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};
export default DriversList;
