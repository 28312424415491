import React, { useState, useEffect, useContext } from "react";
import {
  setDoc,
  doc,
  getDoc,
  collection,
  getCountFromServer,
  where,
  query,
} from "firebase/firestore";
import { db } from "../../utils/firebase.js";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Typography,
  Grid,
  InputBase,
  IconButton,
  Divider,
  Snackbar,
  Alert,
  LinearProgress,
  TextField,
  Switch,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { DeviceContext } from "./devices-list.js";

const DeviceDrawer = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const [meter, setMeter] = useState();
  const [searchSnackBar, setSearchSnackBar] = useState({
    message: "",
    open: false,
  });

  const { drawerProp, setDrawerProp } = useContext(DeviceContext);
  const [loading, setLoading] = useState(drawerProp.open);
  useEffect(() => {
    //  console.log(drawerProp)
    if (drawerProp.license_plate) {
      const m = {
        id: drawerProp.license_plate,
        type: drawerProp.type,
        active: drawerProp.active,
      };
      setMeter(m);
    }
    setLoading(false);
  }, []);

  const handleSnackBarClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSearchSnackBar({ open: false });
  };

  const isLicenseExists = (checkActive) => {
    if (!checkActive) {
      return Promise.resolve();
    } else {
      const ref = collection(db, "devices");
      return getCountFromServer(
        query(
          ref,
          where("license_plate", "==", meter.id),
          where("type", "==", meter.type),
          where("active", "==", true),
        ),
      ).then((snapshot) => {
        // console.log(snapshot.data())
        if (snapshot.data().count === 0) {
          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      });
    }
  };

  const handleSave = (e) => {
    setLoading(true);
    // console.log(meter)
    isLicenseExists(meter.active)
      .then(() => {
        const ref = doc(db, "devices", drawerProp.id);
        setDoc(
          ref,
          { license_plate: meter.id, type: meter.type, active: meter.active },
          { merge: true },
        ).then(() => {
          setLoading(false);
          setDrawerProp({
            open: false,
            license_plate: meter.id,
            type: meter.type,
            id: drawerProp.id,
            active: meter.active,
          });
        });
      })
      .catch(() => {
        setSearchSnackBar({
          open: true,
          message: "License Plate and Type already exisit",
        });
        setLoading(false);
        // console.log("exisit")
      });
    // const ref = doc(db , "devices" , drawerProp.id)
    // setDoc(ref , {license_plate: meter.id , type: meter.type} ,{merge:true}).then(()=>{
    //   setLoading(false)
    //   setDrawerProp({open:false ,license_plate: meter.id , type: meter.type , id: drawerProp.id })
    // })
  };

  const searchPlate = (e) => {
    // console.log("searchValue",searchValue,e)
    setLoading(true);
    getDoc(doc(db, "meters", searchValue))
      .then((dd) => {
        if (dd.exists()) {
          setMeter({ ...dd.data(), id: dd.id });
        } else {
          setSearchSnackBar({ open: true, message: "License Plate Not found" });
        }
      })
      .finally(setLoading(false));
  };

  return (
    <Box component="div" sx={{ m: 3 }} width={400}>
      <Typography marginLeft={2} variant="h6" component="div">
        Device: {drawerProp.id}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper
            component="form"
            sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}
          >
            <InputBase
              valu={searchValue}
              name="license_place"
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search License plate"
              inputProps={{ "aria-label": "Search License plate" }}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={searchPlate}
            >
              <Search />
            </IconButton>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {loading ? (
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        ) : (
          meter && (
            <>
              <Grid
                item
                xs={4}
                alignItems="center"
                container
                justifyContent="flex-end"
              >
                <Typography sx={{ mx: 3, fontSize: 14 }}>License :</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary">
                  {meter.id}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                alignItems="center"
                container
                justifyContent="flex-end"
              >
                <Typography sx={{ mx: 3, fontSize: 14 }}>Type:</Typography>
              </Grid>
              <Grid item xs={8}>
                <FormControl variant="standard" sx={{ minWidth: 120 }}>
                  <Select
                    name="type"
                    label="type"
                    value={meter.type || ""}
                    onChange={(e) =>
                      setMeter({ ...meter, type: e.target.value })
                    }
                  >
                    <MenuItem value="INSTRUCTIVE">Instructive</MenuItem>
                    <MenuItem value="TRIP_INFO">Trip Info</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={4}
                alignItems="center"
                container
                justifyContent="flex-end"
              >
                <Typography sx={{ mx: 3, fontSize: 14 }}>Active:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Switch
                  checked={meter.active ? true : false}
                  onChange={(e) =>
                    setMeter({ ...meter, active: e.target.checked })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Button
                  variant="outlined"
                  onClick={handleSave}
                  disabled={!meter.type}
                >
                  Save
                </Button>
              </Grid>
            </>
          )
        )}
      </Grid>

      <Snackbar
        open={searchSnackBar.open}
        autoHideDuration={6000}
        onClose={handleSnackBarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          severity="error"
          sx={{ width: "100%" }}
          onClose={handleSnackBarClose}
        >
          {searchSnackBar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default DeviceDrawer;
