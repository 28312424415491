import { signOut } from "firebase/auth";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { authentication } from "../utils/firebase.js";

const Logout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    signOut(authentication).then(() => {
      navigate("/login");
    });
  });

  return <div>logout</div>;
};
export default Logout;
