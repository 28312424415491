import React, { useState, useEffect, createContext } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../utils/firebase.js";
import {
  getDoc,
  doc,
  Timestamp,
  setDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import {
  TextField,
  Grid,
  Box,
  Button,
  Drawer,
  CircularProgress,
  Backdrop,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
  FormHelperText,
  LinearProgress,
  Paper,
  Tooltip,
  ClickAwayListener,
  ButtonGroup,
} from "@mui/material";
import { conventDateTime } from "../../utils/util.js";
import TripDrawer from "./trip-drawer.js";
import { isNil } from "lodash";
import "./trip.scss";
import { NumericFormat } from "react-number-format";
import Api from "../../utils/api";
import { LoadingButton } from "@mui/lab";
import { Send } from "@mui/icons-material";

export const TripContext = createContext({});

const ReadOnlyText = (props) => {
  // console.log("v", `${props.value} ${props.name}` )
  return (
    <Grid item xs={12} sm={6}>
      <TextField fullWidth InputProps={{ readOnly: true }} {...props} />
    </Grid>
  );
};

const Trip = () => {
  const api = new Api();
  const [trip, setTrip] = useState({});
  const params = useParams();
  const [disputeLoading, setDisputeLoading] = useState(false);
  const [adjustments, setAdjustments] = useState([]);

  const [tripDrawerProp, setTripDrawerProp] = useState({ open: false });
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (tripDrawerProp.reload) {
      getData();
    }
  }, [tripDrawerProp]);

  const getData = async () => {
    setLoading(true);
    const tripRef = doc(db, "trips", params.id);
    const d = await getDoc(tripRef);
    const data = d.data();

    setTrip({ ...data, id: d.id });

    const adjSnap = getDocs(collection(db, `trips/${params.id}/adjustments`));
    setAdjustments(
      (await adjSnap).docs.map((doc) => ({ ...doc.data(), id: doc.id })),
    );
    setLoading(false);
  };

  const toggleDrawer = (open) => (event) => {
    setTripDrawerProp({ open: open });
  };

  const handleDispute = (event) => {
    const ref = doc(db, "trips", params.id);
    let dvalue = null;
    if (isNil(trip.dispute)) {
      dvalue = { created_on: Timestamp.now(), created_by: "tester" };
    }
    setDisputeLoading(true);
    setDoc(ref, { dispute: dvalue }, { merge: true }).then((doc) => {
      setTrip({ ...trip, dispute: dvalue });
      setDisputeLoading(false);
    });
  };

  const copyText = () => {
    const url = `${process.env.REACT_APP_receipt_url}/${params.id}`;
    navigator.clipboard.writeText(url);
    setCopied(true);
  };

  const resendReceipt = () => {
    setResendLoading(true);
    api.resendReceipt(params.id).then((resp) => {
      // console.log(resp)
      setResendLoading(false);
    });
  };

  return (
    <Box component="div" noValidate sx={{ m: 3 }}>
      {trip.id && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h6">Trip {trip.id}</Typography>
            </Grid>
            <Grid item container justifyContent="flex-end" xs={6}>
              {trip.payment_type !== "DASH_ADJUSTMENT" && (
                <>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={disputeLoading}
                          checked={!isNil(trip.dispute)}
                          onChange={handleDispute}
                        />
                      }
                      label={
                        isNil(trip.dispute)
                          ? "Dispute"
                          : "Dispute (" +
                            trip.dispute.created_by +
                            " " +
                            conventDateTime(trip.dispute.created_on) +
                            ")"
                      }
                    />
                    {disputeLoading && (
                      <FormHelperText>
                        <Box sx={{ width: "100%" }}>
                          <LinearProgress />
                        </Box>
                      </FormHelperText>
                    )}
                  </FormGroup>
                  <ButtonGroup variant="outlined">
                    <ClickAwayListener onClickAway={() => setCopied(false)}>
                      <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        arrow
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        open={copied}
                        onClose={() => setCopied(false)}
                        title="URL Copied"
                      >
                        <Button onClick={copyText}>Copy receipt URL</Button>
                      </Tooltip>
                    </ClickAwayListener>

                    <LoadingButton
                      loading={resendLoading}
                      onClick={resendReceipt}
                      variant="outlined"
                      endIcon={<Send />}
                      loadingPosition="end"
                      disabled={isNil(trip.passenger_information)}
                    >
                      resend receipt
                    </LoadingButton>
                  </ButtonGroup>
                </>
              )}
            </Grid>

            <ReadOnlyText
              name="license_plate"
              value={trip.license_plate || ""}
              label="License Plate"
            />
            <ReadOnlyText
              name="driver"
              value={trip.driver?.id ? trip.driver.id : ""}
              label="Driver"
            />
            <ReadOnlyText
              name="distance"
              value={trip.distance || ""}
              label="Distance"
            />
            <ReadOnlyText name="fare" value={trip.fare || ""} label="Fare" />
            <ReadOnlyText
              name="dash_fee"
              value={trip.dash_fee || ""}
              label="Dash Fee"
            />
            <ReadOnlyText name="extra" value={trip.extra || ""} label="Extra" />
            <ReadOnlyText
              name="tips"
              value={trip.dash_tips || ""}
              label="Tips"
            />
            {trip.payment_type !== "DASH_ADJUSTMENT" && (
              <ReadOnlyText
                name="adjustment"
                value={trip.adjustment || ""}
                label="Adjustment"
              />
            )}
            <ReadOnlyText name="total" value={trip.total || ""} label="Total" />
            <ReadOnlyText
              name="trip_start"
              value={trip.trip_start ? conventDateTime(trip.trip_start) : ""}
              label="Trip Start"
            />
            <ReadOnlyText
              name="trip_end"
              value={trip.trip_start ? conventDateTime(trip.trip_end) : ""}
              label="Trip End"
            />
            <ReadOnlyText
              name="payment_type"
              value={trip.payment_type || ""}
              label="Payment Type"
            />
            <ReadOnlyText
              name="meter_id"
              value={trip.meter_id || ""}
              label="Meter Id"
            />
            {adjustments.map((adj) => (
              <Grid key={adj.id} item textAlign="center" xs={6}>
                <Paper variant="outlined" sx={{ minWidth: 275 }}>
                  <Typography variant="h6">Adjustment: {adj.id}</Typography>
                  <div className="adj_container">
                    <div>
                      <div>Amount</div>
                      <div>
                        <NumericFormat
                          value={adj.total}
                          decimalScale={1}
                          displayType="text"
                          prefix="$"
                          thousandSeparator=","
                        />{" "}
                      </div>
                    </div>
                    <div>
                      <div>Reason</div>
                      <div>{adj.adjustment_information?.reason}</div>
                    </div>
                    <div>
                      <div>Note</div>
                      <div>{adj.adjustment_information?.note}</div>
                    </div>
                    <div>
                      <div>Creation time</div>
                      <div>{conventDateTime(adj.creation_time)}</div>
                    </div>
                  </div>
                </Paper>
              </Grid>
            ))}
            {trip.payment_type !== "DASH_ADJUSTMENT" && (
              <Grid item textAlign="center" xs={12}>
                <Button variant="outlined" onClick={toggleDrawer(true)}>
                  Adjustment
                </Button>
              </Grid>
            )}
          </Grid>

          <TripContext.Provider value={{ tripDrawerProp, setTripDrawerProp }}>
            <Drawer
              anchor="right"
              open={tripDrawerProp.open}
              onClose={toggleDrawer(false)}
            >
              <TripDrawer tripId={trip.id} />
            </Drawer>
          </TripContext.Provider>
        </>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default Trip;
