import { Check, Clear } from "@mui/icons-material";
import {
  Backdrop,
  CircularProgress,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Typography,
  Button,
  Drawer,
} from "@mui/material";

import {
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  getCountFromServer,
  where,
  startAfter,
  endBefore,
  limitToLast,
} from "firebase/firestore";
import React, { useState, useEffect, createContext } from "react";
import { db } from "../../utils/firebase.js";
import { conventDateTime } from "../../utils/util.js";
import DeviceDrawer from "./device-drawer.js";

export const DeviceContext = createContext({});

const DevicesList = () => {
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    first: null,
    last: null,
    page: 0,
    rowsPerPage: 10,
    count: 0,
  });
  const [constraints, setConstraints] = useState([]);

  const defaultDrawer = {
    open: false,
    license_plate: null,
    type: null,
    id: null,
  };

  const [drawerProp, setDrawerProp] = useState(defaultDrawer);

  const getData = async () => {
    setLoading(true);

    const listRef = collection(db, "devices");
    if (pagination.count === 0) {
      const clone_con = constraints.filter((con) => con.type !== "limit");
      const cnt_snapshot = await getCountFromServer(
        query(listRef, ...clone_con),
      );
      setPagination({ ...pagination, count: cnt_snapshot.data().count });
    }

    const q = query(listRef, ...constraints);
    const listSnap = getDocs(q);
    setDevices(
      (await listSnap).docs.map((doc) => ({ ...doc.data(), id: doc.id })),
    );
  };

  useEffect(() => {
    if (constraints.length > 0) {
      getData();
    }
  }, [constraints]);

  useEffect(() => {
    reset();
  }, []);

  useEffect(() => {
    // console.log("list" , devices)
    const last = devices.length > 0 ? devices[devices.length - 1].device : null;
    const first = devices.length > 0 ? devices[0].device : null;
    setPagination({ ...pagination, last: last, first: first });
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices]);

  const reset = () => {
    // constraints.splice(0, constraints.length);
    var con = [
      where("device", "!=", null),
      orderBy("device", "asc"),
      limit(pagination.rowsPerPage),
    ];
    setConstraints(con);
    setPagination({
      ...pagination,
      count: 0,
      first: null,
      last: null,
      page: 0,
    });
  };

  useEffect(() => {
    // console.log(drawerProp)
    if (drawerProp.id) {
      // const row = devices.filter(ele => ele.id === drawerProp.id)
      // console.log("useEff  drawProp " ,row)
      const newDevicesList = devices.map((d) =>
        d.id !== drawerProp.id
          ? d
          : {
              ...d,
              license_plate: drawerProp.license_plate,
              type: drawerProp.type,
              active: drawerProp.active,
            },
      );
      setDevices(newDevicesList);
    }
  }, [drawerProp]);

  const handleChangePage = (event, newPage) => {
    const clone_con = constraints.filter(
      (con) =>
        con.type !== "startAfter" &&
        con.type !== "endBefore" &&
        con.type !== "limit" &&
        con.type !== "limitToLast",
    );
    if (newPage > pagination.page) {
      //next page

      clone_con.push(startAfter(pagination.last));
      clone_con.push(limit(pagination.rowsPerPage));
    } else {
      clone_con.push(endBefore(pagination.first));
      clone_con.push(limitToLast(pagination.rowsPerPage));
    }
    setConstraints(clone_con);
    setPagination({ ...pagination, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setPagination({
      ...pagination,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
      first: null,
      last: null,
      count: 0,
    });
    const clone_con = constraints.filter(
      (con) =>
        con.type !== "startAfter" &&
        con.type !== "endBefore" &&
        con.type !== "limit" &&
        con.type !== "limitToLast",
    );
    clone_con.push(limit(parseInt(event.target.value, 10)));
    setConstraints(clone_con);
  };

  const toggleDrawler = (row) => (event) => {
    if (row != null) {
      setDrawerProp({
        open: true,
        license_plate: row.license_plate,
        type: row.type,
        id: row.id,
        active: row.active ? true : false,
      });
    } else {
      setDrawerProp(defaultDrawer);
    }
  };

  const displayLicense = (row, field) => {
    if (drawerProp.id != null && row.id === drawerProp.id) {
      return drawerProp[field];
    } else {
      return row[field] || "--";
    }
  };

  const renderRow = (row) => {
    return (
      <TableRow key={row.id}>
        <TableCell>{row.active ? <Check /> : <Clear />}</TableCell>
        <TableCell>{row.id}</TableCell>
        <TableCell>{displayLicense(row, "license_plate")}</TableCell>
        <TableCell>{displayLicense(row, "type")}</TableCell>
        <TableCell>{conventDateTime(row.last_update_time)} </TableCell>
        <TableCell>
          <Button variant="outlined" onClick={toggleDrawler(row)}>
            Edit
          </Button>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <div className="admin-right">
      <Typography variant="h5" gutterBottom component="div">
        Device
      </Typography>
      {loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="Device table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Active</TableCell>
                  <TableCell>Device Id</TableCell>
                  <TableCell>License Plate</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Last update</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>{devices.map(renderRow)}</TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={pagination.count}
            rowsPerPage={pagination.rowsPerPage}
            page={pagination.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}

      <DeviceContext.Provider value={{ drawerProp, setDrawerProp }}>
        <Drawer
          anchor="right"
          open={drawerProp.open}
          onClose={toggleDrawler(null)}
        >
          <DeviceDrawer />
        </Drawer>
      </DeviceContext.Provider>
    </div>
  );
};

export default DevicesList;
