import {
  Typography,
  Backdrop,
  Button,
  CircularProgress,
  TableContainer,
  TablePagination,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Drawer,
  ButtonGroup,
} from "@mui/material";
import React, { useState, useEffect, createContext } from "react";
import { db, storage } from "../../utils/firebase.js";
import {
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  getCountFromServer,
  where,
  startAfter,
  endBefore,
  limitToLast,
} from "firebase/firestore";
import { conventDateTime } from "../../utils/util.js";
import BatchDrawer from "./batch-drawer.js";
import { getDownloadURL, ref } from "@firebase/storage";
import { isNil } from "lodash";

export const BatchContext = createContext({});

const BatchesList = () => {
  const [batches, setBatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    first: null,
    last: null,
    page: 0,
    rowsPerPage: 10,
    count: 0,
  });

  const [constraints, setConstraints] = useState([]);

  const defaultDrawer = { open: false };

  const [drawerProp, setDrawerProp] = useState(defaultDrawer);

  const getData = async () => {
    setLoading(true);
    const listRef = collection(db, "batches");

    if (pagination.count === 0) {
      const clone_con = constraints.filter((con) => con.type !== "limit");
      const cnt_snapshot = await getCountFromServer(
        query(listRef, ...clone_con),
      );
      setPagination({ ...pagination, count: cnt_snapshot.data().count });
    }

    const q = query(listRef, ...constraints);
    const listSnap = getDocs(q);
    setBatches(
      (await listSnap).docs.map((doc) => ({ ...doc.data(), id: doc.id })),
    );
  };

  useEffect(() => {
    if (constraints.length > 0) {
      getData();
    }
  }, [constraints]);

  useEffect(() => {
    // console.log("list" , batches)
    const last =
      batches.length > 0 ? batches[batches.length - 1].created_on : null;
    const first = batches.length > 0 ? batches[0].created_on : null;
    setPagination({ ...pagination, last: last, first: first });
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batches]);

  useEffect(() => {
    reset();
  }, []);

  const reset = () => {
    // constraints.splice(0, constraints.length);
    let con = [
      where("created_on", "!=", null),
      orderBy("created_on", "desc"),
      limit(pagination.rowsPerPage),
    ];
    setConstraints(con);
    setPagination({
      ...pagination,
      count: 0,
      first: null,
      last: null,
      page: 0,
    });
  };

  const handleChangePage = (event, newPage) => {
    const clone_con = constraints.filter(
      (con) =>
        con.type !== "startAfter" &&
        con.type !== "endBefore" &&
        con.type !== "limit" &&
        con.type !== "limitToLast",
    );
    if (newPage > pagination.page) {
      //next page

      clone_con.push(startAfter(pagination.last));
      clone_con.push(limit(pagination.rowsPerPage));
    } else {
      clone_con.push(endBefore(pagination.first));
      clone_con.push(limitToLast(pagination.rowsPerPage));
    }
    setConstraints(clone_con);
    setPagination({ ...pagination, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setPagination({
      ...pagination,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
      first: null,
      last: null,
      count: 0,
    });
    const clone_con = constraints.filter(
      (con) =>
        con.type !== "startAfter" &&
        con.type !== "endBefore" &&
        con.type !== "limit" &&
        con.type !== "limitToLast",
    );
    clone_con.push(limit(parseInt(event.target.value, 10)));
    setConstraints(clone_con);
  };

  const downloadFile = (id) => (event) => {
    getDownloadURL(ref(storage, `/batch_files/${id}.txt`)).then((url) => {
      window.open(`${url}`, "_blank");
    });
  };

  const renderRow = (row) => {
    return (
      <TableRow key={row.id}>
        <TableCell>{row.id}</TableCell>
        <TableCell>{row.status}</TableCell>
        <TableCell>{conventDateTime(row.created_on)}</TableCell>
        <TableCell>
          <ButtonGroup variant="outlined" size="small">
            <Button onClick={toggleDrawler(row)}>Edit</Button>
            <Button
              size="small"
              onClick={downloadFile(row.id)}
              disabled={isNil(row.file)}
            >
              Download
            </Button>
          </ButtonGroup>
        </TableCell>
      </TableRow>
    );
  };

  const toggleDrawler = (row) => (event) => {
    if (row != null) {
      console.log("drawer", row);
      setDrawerProp({ open: true, ...row });
    } else {
      setDrawerProp(defaultDrawer);
    }
  };

  return (
    <div className="admin-right">
      <Typography variant="h5" component="div">
        Batches
      </Typography>
      {loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <TableContainer>
            <Table aria-label="Device table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Created on</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>{batches.map(renderRow)}</TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={pagination.count}
            rowsPerPage={pagination.rowsPerPage}
            page={pagination.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
      <BatchContext.Provider value={{ drawerProp, setDrawerProp }}>
        <Drawer
          anchor="right"
          open={drawerProp.open}
          onClose={toggleDrawler(null)}
        >
          <BatchDrawer />
        </Drawer>
      </BatchContext.Provider>
    </div>
  );
};

export default BatchesList;
