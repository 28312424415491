import axios from "axios";

export default class Api {
  constructor() {
    this.api_url = process.env.REACT_APP_api_endpoint;
    this.api_token = null;
  }
  init = () => {
    this.api_token = null;
    let headers = {
      Accept: "application/json",
    };
    if (this.api_token) {
      headers.Authorization = `Bearer ${this.api_token}`;
    }

    this.client = axios.create({
      baseURL: this.api_url,
      timeout: 31000,
      headers: headers,
    });
    return this.client;
  };

  checkDriverActivation = (code) => {
    return this.init().get("/activation/" + code);
  };

  confirmActivation = (code) => {
    return this.init().post(`activation/${code}`);
  };

  fundRelease = (value) => {
    const payload = {
      type: "FUND_RELEASE",
      input: {
        action: "REQUEST_PRERELEASE",
        payload: value,
      },
    };

    return this.init().post("batch", payload);
  };

  tipsAdjustment = (id, payload) => {
    const url = `/trips/${id}/adjustment`;
    return this.init().post(url, payload);
  };
  resendReceipt = (id) => {
    const url = `trips/${id}/resend-receipt`;
    return this.init().post(url);
  };
}
