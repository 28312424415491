import React, { useState, useEffect } from "react";

import {
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  getCountFromServer,
  where,
  startAfter,
  endBefore,
  limitToLast,
} from "firebase/firestore";
import { db, onAuthStateChangedPromise } from "../../utils/firebase.js";
import {
  Table,
  TableBody,
  TableRow,
  TablePagination,
  TableHead,
  TableContainer,
  TableCell,
  Box,
  TextField,
  CircularProgress,
  Grid,
  FormControl,
  InputLabel,
  Paper,
  Collapse,
  IconButton,
  Button,
  Backdrop,
  AppBar,
  Typography,
  Toolbar,
  MenuItem,
  Select,
  Checkbox,
  Snackbar,
  Alert,
  AlertTitle,
  ButtonGroup,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { conventDateTime } from "../../utils/util.js";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { RestartAlt, Search, Close } from "@mui/icons-material";
import { NumericFormat } from "react-number-format";
import Api from "../../utils/api";
import RestApi from "../../utils/rest-api";
import { useSearchParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const SearchByLabel = {
  id: "Id",
  meter_id: "Meter",
  transaction_start: "Transaction start",
  "driver.id": "Driver ID",
  payout_status: "Status",
};

const TransactionsList = () => {
  const api = new Api();
  const restApi = new RestApi();
  let [searchParams, setSearchParams] = useSearchParams();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [currentUser, setCurrentUser] = useState({});

  const [pagination, setPagination] = useState({
    first: null,
    last: null,
    page: 0,
    rowsPerPage: 10,
    count: 0,
  });

  const [searchForm, setSearchForm] = useState({
    fieldName: "",
    fieldValue: "",
    transactionStartFrom: null,
    transactionStartTo: null,
  });

  const [selectedRow, setSelectedRow] = useState([]);

  const [constraints, setConstraints] = useState([]);
  const [checkBoxEnable, setCheckBoxEnable] = useState(false);
  const [alert, setAlert] = useState({
    severity: "info",
    show: false,
    title: "",
    message: "",
  });

  const [queryParam, setQueryParam] = useState({})



  useEffect(() => {

    const last = transactions.length > 0 ? transactions[transactions.length - 1].creation_time : null;
    const first = transactions.length > 0 ? transactions[0].creation_time : null;
    setPagination({ ...pagination, last: last, first: first });

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactions]);

  useEffect(() => {
    searchQuery();
    getCurrentUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    searchQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const searchQuery = ()=>{
    if (searchParams.has("payoutStatus") || searchParams.has("tag")){
      filterByQueries(searchParams);
    }else{
      clickReset();
    }
  }


  useEffect(() => {
    setSelectedRow([]);
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParam]);

  const getCurrentUser = async ()=>{
    const user = await onAuthStateChangedPromise();
    setCurrentUser(user);
  }

  const getData = async () => {
    setLoading(true);

    let qp = {...queryParam};

    qp["limit"] = pagination.rowsPerPage;
    qp["type"] = "TRIP";
    if (!qp["sort"]){
      qp["sort"] = "tripEnd";
      qp["direction"] = "DESC";
    }

    const transactionsRef = restApi.getTransactions(qp);
    const data = (await transactionsRef).data ;
    const cnt = data.count;
    if (pagination.count === 0) {
      pagination.count = cnt;
      setPagination({ ...pagination, count: cnt });
    }
    if (queryParam.offset){
      setPagination({ ...pagination, page: queryParam.offset });

    }else{
      setPagination({ ...pagination, page: 0 });
    }
    

    // setPagination({ ...pagination, count: cnt });
    const transactionsSnap = data.transactions;
    
    setTransactions(
      transactionsSnap.map((doc) => ({ ...doc, id: doc.id })),
    );

  };

  const handleChangePage = (event, newPage) => {



    let offset = newPage;
    let qp = {...queryParam};
    qp["offset"] = offset;

    setQueryParam(qp);
    // queryParam["offset"] = newPage;
    setPagination({ ...pagination, page: newPage });
 
  };

  const handleChangeRowsPerPage = (event) => {


    setPagination({
      ...pagination,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
      first: null,
      last: null,
      count: 0,
    });
    let qp = {...queryParam};
    qp["limit"] = parseInt(event.target.value, 10);

    setQueryParam(qp);

  };

  const clickRow = (row) => {
    console.log(`clickRow`);
    navigate(`/transaction/${row.id}`, { state: { id: row.id } });
  };

  const checkRow = (id) => {
    let selected = [...selectedRow];
    if (!selectedRow.includes(id)) {
      selected.push(id);
    } else {
      selected = selectedRow.filter((v) => v !== id);
    }
    setSelectedRow(selected);
  };

  const checkAll = () => {
    console.log(`checkAll`);

    const selected = [];
    if (selectedRow.length === 0 && selectedRow < transactions.length) {
      transactions.filter(data=>data.payoutStatus!=="RELEASED").forEach((data) => selected.push(data.id));
    }
    setSelectedRow(selected);
  };

  const renderData = (row) => {

    return (
      <TableRow
        key={row.id}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          "&.MuiTableRow-hover": { cursor: "pointer" },
        }}
        onClick={() => clickRow(row)}
        hover={true}
      >
        <TableCell onClick={(e) => e.stopPropagation()}>
          <Checkbox
            checked={selectedRow.includes(row.id)}
            onClick={() => checkRow(row.id)}
            disabled={row.payoutStatus === "RELEASED"}
          />
        </TableCell>
        <TableCell>{row.metadata?.licensePlate}</TableCell>
        <TableCell>{row.metadata?.driver?.id}</TableCell>
        <TableCell>{conventDateTime(row.metadata.tripStart)}</TableCell>
        <TableCell>{conventDateTime(row.metadata.tripEnd)}</TableCell>
        <TableCell>{row.metadata.paymentType}</TableCell>
        <TableCell>{row.payoutStatus}</TableCell>
        <TableCell align="right">
          <NumericFormat
            value={row.metadata.fare}
            decimalScale={1}
            displayType="text"
            prefix={"$"}
            thousandSeparator=","
          />
        </TableCell>
        <TableCell align="right">
          <NumericFormat
            value={row.metadata.dashFee}
            decimalScale={1}
            displayType="text"
            prefix={"$"}
            thousandSeparator=","
          />
        </TableCell>
        <TableCell align="right">
          <NumericFormat
            value={row.metadata.extra}
            decimalScale={1}
            displayType="text"
            prefix={"$"}
            thousandSeparator=","
          />
        </TableCell>
        <TableCell align="right">
          <NumericFormat
            value={row.metadata.dashTips}
            decimalScale={1}
            displayType="text"
            prefix={"$"}
            thousandSeparator=","
          />
        </TableCell>
        <TableCell align="right" sx={{ pr: 2 }}>
          <NumericFormat
            value={row.metadata.total}
            decimalScale={1}
            displayType="text"
            prefix={"$"}
            thousandSeparator=","
          />
        </TableCell>
      </TableRow>
    );
  };

  const handleSearchFormChange = (event) => {
    console.log(`handleSearchFormChange`);

    setSearchForm({ ...searchForm, fieldValue: event.target.value });
  };

  const clickReset = () => {
    
    let qp = {...queryParam};

    delete qp["type"];
    delete qp["payoutStatus"];
    delete qp["paymentStatus"];
    delete qp["metadataFilterKeys"];
    delete qp["metadataFilterValues"];
    delete qp["compareTypes"];
    delete qp["paymentType"];
    delete qp["merchantPhone"];
    delete qp["tag"];
    delete qp["txId"];

    setPagination({
      ...pagination,
      count: 0,
      first: null,
      last: null,
      page: 0,
    });
    setCheckBoxEnable(false);
    setQueryParam(qp);
  };

  const buildMetaQuery = (key, value, op)=>{

    let qp = {...queryParam};

    if (!qp["metadataFilterKeys"]){
      qp["metadataFilterKeys"] = []
    }
    if (!qp["metadataFilterValues"]){
      qp["metadataFilterValues"] = []
    }
    if (!qp["compareTypes"]){
      qp["compareTypes"] = []
    }

    let keyIdx = qp["metadataFilterKeys"].indexOf(key);
    if (keyIdx>-1){
      qp["metadataFilterKeys"].splice(keyIdx, 1);
      qp["metadataFilterValues"].splice(keyIdx, 1);
      qp["compareTypes"].splice(keyIdx, 1);
    }

    qp["metadataFilterKeys"].push(key);
    qp["metadataFilterValues"].push(value);
    qp["compareTypes"].push(op);

    return qp;
  }

  const clickSearch = (event) => {

    console.log(`clickSearch`);

    event.preventDefault();

    console.log(searchForm);

    let query = {};

    if (searchForm.fieldName === "transaction_start") {
      if (searchForm.transactionStartFrom) {
        query = buildMetaQuery("tripStart", searchForm.transactionStartFrom.toDate(), ">=");
      }
      if (searchForm.transactionStartTo) {
        query = buildMetaQuery("tripEnd", searchForm.transactionStartFrom.toDate(), "<=");
      }


      query["sort"] = "tripStart";
      query["direction"] = "DESC";
    } else if (searchForm.fieldName === "id") {
      query = {...queryParam}
      query["txId"] = searchForm.fieldValue;


    } else {
      // con.push(where(searchForm.fieldName, "==", searchForm.fieldValue));
      // con.push(limit(pagination.rowsPerPage));
      // con.push(orderBy("creation_time", "desc"));
    }
    // setConstraints(con);
    setQueryParam(query);

    setPagination({
      ...pagination,
      count: 0,
      first: null,
      last: null,
      page: 0,
    });
    setSelectedRow([]);
    setCheckBoxEnable(false);
  };

  const filterByQueries = (queries) =>{
    
    if (queries.has("payoutStatus")){
      queryParam["payoutStatus"] = queries.get("payoutStatus");
    }else{
      delete queryParam["payoutStatus"];
    }
    
    if (queries.has("tag")){
      queryParam["tag"] = queries.get("tag");
    }else{
      delete queryParam["tag"];
    }

    if(queryParam["tag"] === "OPEN_SALE" || queryParam["tag"] === "OPEN_AUTH"){
      delete queryParam["paymentType"];
    } else {
      queryParam["paymentType"] = "DASH";
    }
    
    queryParam["sort"] = "tripStart";
    queryParam["direction"] = "DESC";


    // setConstraints(con);
    setPagination({
      ...pagination,
      count: 0,
      first: null,
      last: null,
      page: 0,
    });
    // setCheckBoxEnable(true);
    setCheckBoxEnable(false);
    getData();
  }


  const preRelease = async () => {

    if (selectedRow.length > 0) { 

      const payload = {txIds:selectedRow, bankName:"DBS"};
      setLoading(true);

      restApi
        .createTransactionsPayout(payload)
        .then((resp) => {
          // console.log(resp)
          // var anyFail = resp.data.filter(v => v.result ==="FAILED")
          const ale = {
            severity: "info",
            show: true,
            title: "Update success",
            message: "update PreRelese success",
          };
          setAlert(ale);
          clickReset();
        })
        .catch((err) => {
          console.log("err", err);
          const ale = {
            severity: "error",
            show: true,
            title: "System Error",
            message: err.response.headers,
          };
          setAlert(ale);
        })
        .finally(() => {
          setLoading(false);
        });
        
    }
  };

  return (
    <div style={{ margin: 8 }}>
      <AppBar position="static" color="transparent">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Transactions
          </Typography>
          <ButtonGroup variant="outlined" size="small">
            <Button onClick={() => setExpanded(!expanded)}>Search</Button>
            {/* <Button onClick={filterPayoutStatus("PRERELEASED")}>Find PreRelease</Button>
            <Button onClick={filterPayoutStatus("RELEASED")}>Find Release</Button> */}
            <Button onClick={preRelease} disabled={selectedRow.length === 0}>
              PreRelese
            </Button>
          </ButtonGroup>
        </Toolbar>
      </AppBar>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box
          component="fieldset"
          sx={{ p: 2, borderColor: "grey.500" }}
          noValidate
          autoComplete="off"
          borderRadius={2}
        >
          <legend>
            <Typography margin={1}>Search</Typography>
          </legend>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <FormControl variant="standard" fullWidth>
                <InputLabel>Field</InputLabel>
                <Select
                  value={searchForm.fieldName || ""}
                  onChange={(event) =>
                    setSearchForm({
                      ...searchForm,
                      fieldName: event.target.value,
                      fieldValue: null,
                      transactionStartFrom: null,
                      transactionStartTo: null,
                    })
                  }
                >
                  {Object.keys(SearchByLabel).map((key, idx) => {
                    return (
                      <MenuItem key={`mitem-${key}`} value={key}>
                        {SearchByLabel[key]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            {(searchForm.fieldName === "transaction_start" && (
              <LocalizationProvider Grid container dateAdapter={AdapterMoment}>
                <DateTimePicker
                  renderInput={(props) => (
                    <Grid item xs={3}>
                      <TextField
                        name="transactionStartFrom"
                        {...props}
                        variant="standard"
                        fullWidth
                      />
                    </Grid>
                  )}
                  label="Transaction start from"
                  value={searchForm.transactionStartFrom}
                  inputFormat="DD/MM/YYYY HH:mm"
                  onChange={(newValue) => {
                    setSearchForm({ ...searchForm, transactionStartFrom: newValue });
                  }}
                />

                <DateTimePicker
                  renderInput={(props) => (
                    <Grid item xs={3}>
                      <TextField
                        name="transactionStartTo"
                        {...props}
                        variant="standard"
                        fullWidth
                      />
                    </Grid>
                  )}
                  label="Transaction start to"
                  value={searchForm.transactionStartTo}
                  inputFormat="DD/MM/YYYY HH:mm"
                  onChange={(newValue) => {
                    setSearchForm({ ...searchForm, transactionStartTo: newValue });
                  }}
                />
              </LocalizationProvider>
            )) ||
              (searchForm.fieldName === "payout_status" && (
                <Grid item xs={6}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel htmlFor="uncontrolled-native">
                      Status
                    </InputLabel>
                    <Select
                      value={searchForm.fieldValue || ""}
                      onChange={handleSearchFormChange}
                    >
                      <MenuItem value="NONE">NONE</MenuItem>
                      <MenuItem value="PRERELEASE">PRERELEASE</MenuItem>
                      <MenuItem value="RELEASED">RELEASED</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )) ||
              (searchForm.fieldName && (
                <Grid item xs={6}>
                  <TextField
                    label={SearchByLabel[searchForm.fieldName]}
                    variant="standard"
                    fullWidth
                    name={searchForm.fieldName}
                    value={searchForm.fieldValue || ""}
                    onChange={handleSearchFormChange}
                  />{" "}
                </Grid>
              ))}
            <Grid item xs={3}>
              <Button
                sx={{ mt: 1 }}
                variant="outlined"
                endIcon={<Search />}
                onClick={clickSearch}
              >
                Search
              </Button>
              <Button
                sx={{ mt: 1, ml: 1 }}
                variant="outlined"
                endIcon={<RestartAlt />}
                onClick={clickReset}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Collapse>

      {loading ? (
        "loading"
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label="Driver table" size="small" padding="none">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    color="primary"
                    checked={
                      transactions.length > 0 && selectedRow.length === transactions.length
                    }
                    indeterminate={
                      selectedRow.length > 0 &&
                      selectedRow.length < transactions.length
                    }
                    onClick={checkAll}
                    disabled={transactions.filter((tx)=>tx.payoutStatus==="RELEASED").length === transactions.length }
                  />
                </TableCell>
                <TableCell>License Plate </TableCell>
                <TableCell>Driver</TableCell>
                <TableCell>Transaction Start</TableCell>
                <TableCell>Transaction End</TableCell>
                <TableCell>Payment Type</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="right">Fare</TableCell>
                <TableCell align="right">Dash Fee</TableCell>
                <TableCell align="right">Extra</TableCell>
                <TableCell align="right">Tips</TableCell>
                <TableCell align="right" sx={{ pr: 2 }}>
                  Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{transactions.map(renderData)}</TableBody>
          </Table>
        </TableContainer>
      )}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={pagination.count}
        rowsPerPage={pagination.rowsPerPage}
        page={pagination.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar open={alert.show} sx={{ width: "95%" }}>
        <Alert
          severity={alert.severity}
          sx={{ width: "100%" }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlert({ ...alert, show: false });
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle>{alert.title}</AlertTitle>
          {alert.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default TransactionsList;
