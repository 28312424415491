import axios from "axios";
import { authentication, onAuthStateChangedPromise } from "../utils/firebase.js";
import { getAuth, onAuthStateChanged } from "firebase/auth";

export default class RestApi {
  constructor() {
    this.api_url = process.env.REACT_APP_rest_api_endpoint;
    this.api_token = localStorage.getItem("token");
  }
  init = async () => {

    // this.api_token = localStorage.getItem("token");

    // firebase.auth().verifyIdToken(this.api_token);
    // this.api_token = await getAuth().currentUser.getIdTokenResult();

    const user = await onAuthStateChangedPromise();
    this.api_token =  (await user.getIdTokenResult()).token;

    let headers = {
      Accept: "application/json",
    };

    // console.log(`token = ${localStorage.getItem("token")}`);
    if (this.api_token) {
      headers.Authorization = `Bearer ${this.api_token}`;
    }

    this.client = axios.create({
      baseURL: this.api_url,
      timeout: 31000,
      headers: headers,
    });
    return this.client;
  };

  getTransactions = async (queryParams)=>{
    return (await (this.init())).get("/transactions", { params: queryParams } );
  }

  getTransactionByTxId = async (txId)=>{
    return (await (this.init())).get(`/transactions/${txId}`);
  }

  getTransactionReceiptByTxId = async (txId)=>{
    return (await (this.init())).get(`/transactions/${txId}/receipt`);
  }

  tagTransactionByTxId = async (txId, payload)=>{
    return (await (this.init())).post(`/transactions/${txId}/tags`, payload);
  }

  remoteTransactionTagsByTxId = async (txId, tagIds)=>{
    return (await (this.init())).delete(`/transactions/${txId}/tags`, { params: {ids: tagIds} });
  }

  adjustTransactionByTxId = async (txId, payload)=>{
    return (await (this.init())).post(`/transactions/${txId}/adjustments`, payload);
  }


  createTransactionsPayout = async (payload)=>{
    return (await (this.init())).post(`/transactions/payouts`, payload);
  }

  voidPaymentByPaymentTxId = async (paymentTxId)=>{
    return (await (this.init())).post(`/admin/payments/${paymentTxId}/void`);
  }

  capturePaymentByPaymentTxId = async (paymentTxId, payload)=>{
    return (await (this.init())).post(`/admin/payments/${paymentTxId}/capture`, payload);
  }

}
