import {
  FormControlLabel,
  Grid,
  Typography,
  Paper,
  Checkbox,
  Divider,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useContext } from "react";
import { BatchContext } from "./batches-list";
import { styled } from "@mui/material/styles";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const BatchDrawer = (props) => {
  const [loading, setLoading] = useState(props.open);
  const { drawerProp, setDrawerProp } = useContext(BatchContext);

  const handleSave = () => {};

  return (
    <Box component="div" sx={{ m: 3 }} width={400}>
      <Typography margin={2} variant="h6" component="div">
        Driver : {drawerProp.id}
      </Typography>
      <Grid container spacing={2}>
        {drawerProp.original_input?.map((item) => (
          <Grid item xs={6} key={item.driver_id}>
            <Item>
              <FormControlLabel
                value={item.driver_id}
                control={<Checkbox />}
                label={item.driver_id}
              />
            </Item>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Button variant="outlined" onClick={handleSave}>
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BatchDrawer;
