import { Outlet, Navigate, useNavigate } from "react-router-dom";
import SideNav from "../components/side-nav/side-nav";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import React, { useState, useEffect } from "react";

const PrivateRoutes = () => {
  const navigate = useNavigate();
  const [loginStatus, setLoginStatus] = useState(true);

  useEffect(() => {
    if (!loginStatus) {
      try{
        navigate("/login");
      }catch (e){
        console.info(e);
      }
      
    }
  }, [loginStatus]);

  onAuthStateChanged(getAuth(), (user) => {
    if (!user) {
      setLoginStatus(false);
    }
  });

  return <div className="container">
    <SideNav />
    <main>
      <Outlet />
    </main>
  </div>;
};

export default PrivateRoutes;
