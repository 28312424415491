import moment from "moment";
import { Timestamp } from "firebase/firestore";
export const conventDateTime = (datetime) => {
  let ret = "--";
  let m = null;
  if (datetime instanceof Timestamp) {
    m = moment(datetime.toDate());    
  }else if (typeof datetime === "string") {
    m = moment(datetime);    
  }

  if (m){
    ret = m.format("DD/MM/YYYY HH:mm");
  }
  
  return ret;
};

export const conventDate = (datetime) => {
  let ret = "--";
  let m = null;
  if (datetime instanceof Timestamp) {
    m = moment(datetime.toDate());
  }else if (typeof datetime === "string") {
    m = moment(datetime);    
  }

  if (m){
    ret = m.format("DD/MM/YYYY HH:mm");
  }
  return ret;
};

export const conventTime = (datetime) => {
  let ret = "--";
  let m = null;
  if (datetime instanceof Timestamp) {
    m = moment(datetime.toDate());
  }else if (typeof datetime === "string") {
    m = moment(datetime);    
  }

  if (m){
    ret = m.format("DD/MM/YYYY HH:mm");
  }
  return ret;
};
