import React, { useState, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { LockOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  TextField,
  Typography,
  Avatar,
  Box,
  Alert,
  Container,
} from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { authentication } from "../utils/firebase.js";
import { getAuth, setPersistence, signInWithEmailAndPassword, browserSessionPersistence, browserLocalPersistence } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";

const theme = createTheme();

const Login = () => {
  const navigate = useNavigate();

  const [credential, setCredential] = useState({ username: "", password: "" });
  const [invalidCode, setInvalidCode] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loginStatus, setLoginStatus] = useState(false);

  useEffect(() => {
    if (loginStatus) {
      try{
        navigate("/dashboard");
      }catch (e){
        console.info(e);
      }
      
    }
  }, [loginStatus]);

  const signIn = () => {
    setInvalidCode(false);

    const auth = getAuth();

    setPersistence(auth, browserLocalPersistence)
    .then(() => {
      // Existing and future Auth states are now persisted in the current
      // session only. Closing the window would clear any existing state even
      // if a user forgets to sign out.
      // ...
      // New sign-in will be persisted with session persistence.
      return signInWithEmailAndPassword(auth, credential.username, credential.password);
    }).then(async (res)=>{
      console.log("login success");
      console.log(res);
      try{
        setLoginStatus(true);
      }catch (e){
        console.error(e);
      }
      
    })
    .catch((error) => {
      console.error(error);
      setInvalidCode(true);
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            marginBottom: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlined />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Email"
              helperText="Admin email"
              value={credential.username}
              onChange={(e) =>
                setCredential({ ...credential, username: e.target.value })
              }
            />
            <FormControl fullWidth variant="outlined" sx={{ mb: 2, mt: 2 }}>
              <InputLabel htmlFor="outlined-adornment-password">
                Password *
              </InputLabel>
              <OutlinedInput
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={(e) => {
                        setShowPassword((show) => !show);
                      }}
                      onMouseDown={(e) => {
                        e.preventDefault();
                      }}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                value={credential.password}
                onChange={(e) =>
                  setCredential({ ...credential, password: e.target.value })
                }
              />
            </FormControl>
            <Button
              fullWidth
              variant="contained"
              disabled={
                isEmpty(credential.username) || isEmpty(credential.password)
              }
              onClick={signIn}
            >
              Submit
            </Button>
            {invalidCode && (
              <Alert sx={{ mt: 1 }} severity="error">
                User or password invalid
              </Alert>
            )}
          </Box>
        </Box>
        <div id="recaptcha-container"></div>
      </Container>
    </ThemeProvider>
  );
};

export default Login;
