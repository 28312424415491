import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SpeedIcon from "@mui/icons-material/Speed";
import ModeOfTravelIcon from "@mui/icons-material/ModeOfTravel";
import ReceiptIcon from '@mui/icons-material/Receipt';

import { AutoAwesomeMotion, Smartphone, Logout } from "@mui/icons-material";

export const navData = [
  {
    id: 0,
    icon: <HomeIcon />,
    text: "Dashboard",
    link: "/dashboard",
  },
  { id: 1, icon: <ManageAccountsIcon />, text: "Drivers", link: "/drivers" },
  { id: 2, icon: <SpeedIcon />, text: "Meters", link: "/meters" },
  {
    id: 3,
    icon: <Smartphone />,
    text: "Device",
    link: "/devices",
  },
  { id: 4, icon: <ModeOfTravelIcon />, text: "Trips", link: "/trips" },
  { id: 5, icon: <ReceiptIcon />, text: "Transactions", link: "/transactions" },
  { id: 5.1, isSubMenu:true , text: "Unreleased", link: "/transactions?payoutStatus=NONE&tag=NONE" },
  { id: 5.2, isSubMenu:true, text: "Pre-Released", link: "/transactions?payoutStatus=PRERELEASED" },
  { id: 5.3, isSubMenu:true, text: "Released", link: "/transactions?payoutStatus=RELEASED" },
  { id: 5.4, isSubMenu:true, text: "Dispute", link: "/transactions?tag=DISPUTE" },
  { id: 5.5, isSubMenu:true, text: "Amount Mismatch", link: "/transactions?tag=AMOUNT_MISMATCH" },
  { id: 5.6, isSubMenu:true, text: "Unable to Void", link: "/transactions?tag=UNABLE_TO_VOID" },
  { id: 5.7, isSubMenu:true, text: "Unable to Capture", link: "/transactions?tag=UNABLE_TO_CAPTURE" },
  { id: 5.8, isSubMenu:true, text: "Open Auth", link: "/transactions?tag=OPEN_AUTH" },
  { id: 5.8, isSubMenu:true, text: "Open Sales", link: "/transactions?tag=OPEN_SALE" },
  { id: 5.9, isSubMenu:true, text: "Payout Rejected", link: "/transactions?tag=PAYOUT_REJECTED" },
  { id: 5.11, isSubMenu:true, text: "No Tag", link: "/transactions?tag=NONE" },
  {
    id: 6,
    icon: <AutoAwesomeMotion />,
    text: "Batch",
    link: "/batches",
  },
  { id: 7, icon: <Logout />, text: "Logout", link: "/logout" },
];
