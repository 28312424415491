import React, { useState, useEffect, createContext } from "react";
import { useParams } from "react-router-dom";

import {
  getDoc,
  doc,
  Timestamp,
  setDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import {
  TextField,
  Grid,
  Box,
  Button,
  Drawer,
  CircularProgress,
  Backdrop,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
  FormHelperText,
  LinearProgress,
  Paper,
  Tooltip,
  ClickAwayListener,
  ButtonGroup,
  Chip,
  Modal
} from "@mui/material";
import { conventDateTime } from "../../utils/util.js";
import TransactionDrawer from "./transaction-drawer.js";
import TransactionCaptureDrawer from "./transaction-capture-drawer.js";
import TransactionAddTagDrawer from "./transaction-add-tag-drawer.js";
import { isNil } from "lodash";
import "./transaction.scss";
import { NumericFormat } from "react-number-format";
import Api from "../../utils/api";
import RestApi from "../../utils/rest-api";
import { LoadingButton } from "@mui/lab";
import { Send } from "@mui/icons-material";
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import BlockIcon from '@mui/icons-material/Block';

export const TransactionContext = createContext({});

const ReadOnlyText = (props) => {
  // console.log("v", `${props.value} ${props.name}` )
  return (
    <Grid item xs={12} sm={6}>
      <TextField fullWidth InputProps={{ readOnly: true }} {...props} />
    </Grid>
  );
};

const Transaction = () => {

  const api = new Api();
  const restApi = new RestApi();
  const [transaction, setTransaction] = useState({});
  const [transactionDetail, setTransactionDetail] = useState({});
  const [paymentTxs, setPaymentTxs] = useState([]);


  const params = useParams();
  const [disputeLoading, setDisputeLoading] = useState(false);
  const [disputeStatus, setDisputeStatus] = useState({});
  const [adjustments, setAdjustments] = useState([]);

  const [transactionDrawerProp, setTransactionDrawerProp] = useState({ open: false });
  const [transactionCaptureDrawerProp, setTransactionCaptureDrawerProp] = useState({ open: false, amount: 0 });
  const [addTagDrawerProp, setAddTagDrawerProp ] = useState({ open: false, tag: "DISPUTE", note:"" });

  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [deleteTagStatus, setDeleteTagStatus] = useState(false);
  const [modalProp, setModalProp] = useState({open: false, text: ""});
  const [deleteTagModalProp, setDeleteTagModalProp] = useState({open: false, tagId: null});

  const paymentTableCols = [
    { field: 'id', headerName: 'ID', flex: 1},
    { field: 'amount', headerName: 'Amount' , flex: 0.3},
    { field: 'cardNumber', headerName: 'Card No.' , flex: 0.5},
    { field: 'paymentMethod', headerName: 'Payment Method', flex: 0.3},
    { field: 'type', headerName: 'Type', flex: 0.3},
    { field: 'status', headerName: 'Status', flex: 0.3},
    { field: 'actions', type:"actions", flex:0.5, 
    renderCell: (params) => {

      const capturePayment = (e) =>{
        e.stopPropagation();
        console.log(`capture ${params.row.id}, ${transactionDetail.total}`)
        setTransactionCaptureDrawerProp({...transactionCaptureDrawerProp, open:true, paymentTxId:params.row.id, amount:transactionDetail.total});
        setTransactionDrawerProp({ open: false });
      }

      const voidPayment = (e) => {
        e.stopPropagation();
        setLoading(true);
        restApi.voidPaymentByPaymentTxId(params.row.id).then(d=>{
          setLoading(false);
        }).catch(e=>{
          console.log(e);
          setLoading(false);
          setModalProp({...modalProp, open:true, text:e.response.data.description});
        });
      };

      return (<Box component="div">
        {params.row.canDoVoidOrCapture && (
          <Box component="div" >
            <Button sx={{ mr: "10px" }} onClick={capturePayment} variant="contained" color="success">Capture</Button>
            <Button onClick={voidPayment} variant="contained" color="error">Void</Button>
          </Box>
        )}
        </Box>);
    }
     }    
  ]

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (transactionDrawerProp.reload || transactionCaptureDrawerProp.reload || addTagDrawerProp.reload) {
      getData();
    }
  }, [transactionDrawerProp, transactionCaptureDrawerProp, addTagDrawerProp]);


  useEffect(() => {
    if (disputeStatus) {
      getData();
    }
  }, [disputeStatus]);

  useEffect(() => {
    if (!deleteTagStatus) {
      getData();
    }
  }, [deleteTagStatus]);

  const getData = async () => {

    setLoading(true);

    const [ txResData, txsResData] = await Promise.all([restApi.getTransactionByTxId(params.id), restApi.getTransactions({txId:params.id} ) ]);
    const data = txsResData.data.transactions[0];
    const txDetail = txResData.data;

    setTransaction({ ...data, id: data.id });
    setTransactionDetail({...txDetail, id: data.id});
    setPaymentTxs(txDetail.paymentTx);
    // setTransactionCaptureDrawerProp({...transactionCaptureDrawerProp, amount:data.total})
    // const adjSnap = getDocs(collection(db, `transactions/${params.id}/adjustments`));
    // setAdjustments(
    //   (await adjSnap).docs.map((doc) => ({ ...doc.data(), id: doc.id })),
    // );
    setLoading(false);
  };

  const toggleDrawer = (open) => (event) => {
    setTransactionDrawerProp({ open: open });
    if (open){
      setTransactionCaptureDrawerProp({ open: !open });
      setAddTagDrawerProp({ open: !open });
    }
  };

  const toggleCaptureDrawer = (open) => (event) => {

    setTransactionCaptureDrawerProp({...transactionCaptureDrawerProp, open: open});
    if (open){
      setTransactionDrawerProp({ open: !open });
      setAddTagDrawerProp({ open: !open });
    }
  };

  const toggleAddTagDrawer = (open) => (event) => {
    setAddTagDrawerProp({ ...addTagDrawerProp, open: open });
    if (open){
      setTransactionCaptureDrawerProp({ open: !open });
      setTransactionDrawerProp({ open: !open });
    }
  };

  const handleCloseModal = () => setModalProp({open: false, text: ""});

  const handleDispute = (event) => {
    let toDispute = true;
    if (transaction.txtag?.filter(txTag=>txTag.tag==="DISPUTE" && txTag.removedAt == null).length > 0){
      toDispute = false;
    }
    
    setDisputeLoading(true);

    if (toDispute){
      const tagBody = {
        "tags": [
          {
            "tag": "DISPUTE",
            "createdBy": "tester",
            "note": "n/a"
          }
        ]
      }
      restApi.tagTransactionByTxId(params.id, tagBody).then(data=>{
        setDisputeStatus({
          ...disputeStatus, a:data.data
        })
      });
    }else{
      restApi.remoteTransactionTagsByTxId(params.id, [transaction.txTag?.filter(txTag=>txTag.tag==="DISPUTE"  && txTag.removedAt == null)[0].id]).then(data=>{
        setDisputeStatus({
          ...disputeStatus, a:data.data
        })        
      })  ;
    }

    setDisputeLoading(false);
   
  };

  const copyText = () => {
    const url = `${process.env.REACT_APP_receipt_url}/${params.id}`;
    navigator.clipboard.writeText(url);
    setCopied(true);
  };

  const resendReceipt = () => {
    setResendLoading(true);
    api.resendReceipt(params.id).then((resp) => {
      // console.log(resp)
      setResendLoading(false);
    });
  };

  const deleteTag = (tagId) =>{
    setDeleteTagStatus(true);
    restApi.remoteTransactionTagsByTxId(params.id, [tagId])
    .then(data=>{
      setDeleteTagStatus(false);
      setDeleteTagModalProp({...deleteTagModalProp, open:false})
    })  ;

  
  }

  return (
    <Box component="div" noValidate sx={{ m: 3 }}>
      {transaction.id && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h6">Transaction {transaction.id}</Typography>
            </Grid>
            <Grid item container justifyContent="flex-end" xs={6} >
              {transaction.paymentType !== "DASH_ADJUSTMENT" && (
                <>

                  <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      disabled={disputeLoading}
                                      checked={!isNil(transaction.txtag) && transaction.txtag?.filter(txTag=>txTag.tag==="DISPUTE" && txTag.removedAt == null).length > 0 > 0 }
                                      onChange={handleDispute}
                                    />
                                  }
                                  label={
                                    isNil(transaction.dispute)
                                      ? "Dispute"
                                      : "Dispute (" +
                                        transaction.dispute.created_by +
                                        " " +
                                        conventDateTime(transaction.tripStart) +
                                        ")"
                                  }
                                />
                                {disputeLoading && (
                                  <FormHelperText>
                                    <Box sx={{ width: "100%" }}>
                                      <LinearProgress />
                                    </Box>
                                  </FormHelperText>
                                )}
                    </FormGroup>



                  <ButtonGroup variant="outlined" >

                  {transaction.paymentType !== "DASH_ADJUSTMENT" && (
                      <Button variant="outlined" onClick={toggleDrawer(true)}>
                        Adjustment
                      </Button>
                  )}
                    <Button variant="outlined" onClick={toggleAddTagDrawer(true)}>
                        Add Tag
                    </Button>
                    <ClickAwayListener onClickAway={() => setCopied(false)}>
                      <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        arrow
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        open={copied}
                        onClose={() => setCopied(false)}
                        title="URL Copied"
                      >
                        <Button onClick={copyText}>Copy receipt URL</Button>
                      </Tooltip>
                    </ClickAwayListener>

                    <LoadingButton
                      loading={resendLoading}
                      onClick={resendReceipt}
                      variant="outlined"
                      endIcon={<Send />}
                      loadingPosition="end"
                      disabled={isNil(transaction.passengerInformation)}
                    >
                      resend receipt
                    </LoadingButton>
                  </ButtonGroup>
                </>
              )}
            </Grid>
            <Grid item container xs={12}>
              {                  
                  transaction.txtag
                  ?.filter(txTag=>txTag.removedAt == null)
                  ?.map(data=>{
                    let tagDisplay = data.tag;
                    switch (data.tag){
                      case "DISPUTE": tagDisplay = "Dispute"; break;
                      case "AMOUNT_MISMATCH": tagDisplay = "Amount Mismatch"; break;
                      case "UNABLE_TO_VOID": tagDisplay = "Unable to Void"; break;
                      case "UNABLE_TO_CAPTURE": tagDisplay = "Unable to Capture"; break;
                      case "OPEN_AUTH": tagDisplay = "Open Auth"; break;
                      case "PAYOUT_REJECTED": tagDisplay = "Payout Rejected"; break;
                      default:;
                    }
                    return (                      
                      <Chip key={data.id} label={tagDisplay} onDelete={()=>{setDeleteTagModalProp({...deleteTagModalProp, open:true, tagId:data.id, tag:tagDisplay});}}/>                      
                    )
                  })
                 }
            </Grid>
            <ReadOnlyText
              name="license_plate"
              value={transaction.metadata.licensePlate || ""}
              label="License Plate"
            />
            <ReadOnlyText
              name="driver"
              value={transaction.metadata.driver?.id ? transaction.metadata.driver.id : ""}
              label="Driver"
            />
            <ReadOnlyText
              name="distance"
              value={transaction.metadata.distance || ""}
              label="Distance"
            />
            <ReadOnlyText name="fare" value={transaction.metadata.fare || ""} label="Fare" />
            <ReadOnlyText
              name="dash_fee"
              value={transaction.dashFee || ""}
              label="Dash Fee"
            />
            <ReadOnlyText name="extra" value={transaction.metadata.extra || ""} label="Extra" />
            <ReadOnlyText
              name="tips"
              value={transaction.dashTips || ""}
              label="Tips"
            />
            {transaction.paymentType !== "DASH_ADJUSTMENT" && (
              <ReadOnlyText
                name="adjustment"
                value={transaction.adjustment || ""}
                label="Adjustment"
              />
            )}
            <ReadOnlyText name="total" value={transaction.total || ""} label="Total" />
            <ReadOnlyText
              name="transaction_start"
              value={transaction.metadata.tripStart ? conventDateTime(transaction.metadata.tripStart) : ""}
              label="Transaction Start"
            />
            <ReadOnlyText
              name="transaction_end"
              value={transaction.metadata.tripEnd ? conventDateTime(transaction.metadata.tripEnd) : ""}
              label="Transaction End"
            />
            <ReadOnlyText
              name="payment_type"
              value={transaction.metadata.paymentType || ""}
              label="Payment Type"
            />
            <ReadOnlyText
              name="meter_id"
              value={transaction.metadata.meterId || ""}
              label="Meter Id"
            />
            {adjustments.map((adj) => (
              <Grid key={adj.id} item textAlign="center" xs={6}>
                <Paper variant="outlined" sx={{ minWidth: 275 }}>
                  <Typography variant="h6">Adjustment: {adj.id}</Typography>
                  <div className="adj_container">
                    <div>
                      <div>Amount</div>
                      <div>
                        <NumericFormat
                          value={adj.total}
                          decimalScale={1}
                          displayType="text"
                          prefix="$"
                          thousandSeparator=","
                        />{" "}
                      </div>
                    </div>
                    <div>
                      <div>Reason</div>
                      <div>{adj.adjustmentInformation?.reason}</div>
                    </div>
                    <div>
                      <div>Note</div>
                      <div>{adj.adjustmentInformation?.note}</div>
                    </div>
                    <div>
                      <div>Creation time</div>
                      <div>{conventDateTime(adj.creationTime)}</div>
                    </div>
                  </div>
                </Paper>
              </Grid>
            ))}

          </Grid>

          <TransactionContext.Provider value={{ transactionDrawerProp, setTransactionDrawerProp }}>
            <Drawer
              anchor="right"
              open={transactionDrawerProp.open}
              onClose={toggleDrawer(false)}
            >
              <TransactionDrawer transactionId={transaction.id} />
            </Drawer>
          </TransactionContext.Provider>

          <TransactionContext.Provider value={{ transactionCaptureDrawerProp, setTransactionCaptureDrawerProp, modalProp, setModalProp }}>
            <Drawer
              anchor="right"
              open={transactionCaptureDrawerProp.open}
              onClose={toggleCaptureDrawer(false)}
            >
              <TransactionCaptureDrawer transactionId={transaction.id} />
            </Drawer>
          </TransactionContext.Provider>

          <TransactionContext.Provider value={{ addTagDrawerProp, setAddTagDrawerProp, modalProp, setModalProp }}>
            <Drawer
              anchor="right"
              open={addTagDrawerProp.open}
              onClose={toggleAddTagDrawer(false)}
            >
              <TransactionAddTagDrawer transactionId={transaction.id} />
            </Drawer>
          </TransactionContext.Provider>
        </>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <DataGrid sx={{ mt: "20px" }}
        rows={paymentTxs}
        columns={paymentTableCols}
        // checkboxSelection
        disableRowSelectionOnClick
        hideFooterPagination
      />

      <Modal
        open={modalProp.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Error
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {modalProp.text}
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={deleteTagModalProp.open}
        onClose={()=>setDeleteTagModalProp({...deleteTagModalProp, open:false})}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure to delete "{deleteTagModalProp.tag}"
          </Typography>
          <Box sx={{ mt: "20px" }}>
            <Button variant="contained" color="success" sx={{ mr: "20px" }} onClick={()=>deleteTag(deleteTagModalProp.tagId)}>Confirm</Button>
            <Button variant="contained" color="error" onClick={()=>setDeleteTagModalProp({...deleteTagModalProp, open:false})}>Cancel</Button>
          </Box>
        </Box>
      </Modal>


    </Box>

  );
};

export default Transaction;
