import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";

import { db } from "../../utils/firebase.js";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
const MetersList = () => {
  const nav = useNavigate();
  const [meters, setMeters] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getData = async () => {
      const meterSnap = getDocs(collection(db, "meters"));
      setMeters(
        (await meterSnap).docs.map((doc) => ({ ...doc.data(), id: doc.id })),
      );
      setLoading(false);
    };
    getData();
  }, []);

  const renderData = (row) => {
    // console.log(row)
    return (
      <TableRow
        key={row.id}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          "&.MuiTableRow-hover": { cursor: "pointer" },
        }}
        hover={true}
        onClick={() => nav(`/meter/${row.id}`, { state: { id: row.id } })}
      >
        <TableCell>{row.id}</TableCell>
      </TableRow>
    );
  };
  return (
    <div className="admin-right">
      <h2>Meter</h2>
      {loading ? (
        "loading"
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="Driver table">
            <TableHead>
              <TableRow>
                <TableCell>License Plate</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {meters.map((data) => data.id && renderData(data))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default MetersList;
