import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { db } from "../../utils/firebase.js";
import { conventDateTime } from "../../utils/util.js";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  doc,
  getDoc,
  collection,
  getDocs,
  query,
  orderBy,
  limit,
} from "firebase/firestore";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const Meter = () => {
  const nav = useNavigate();
  const theme = createTheme();
  const location = useLocation();
  const [meter, setMeter] = useState({});
  const [trips, setTrips] = useState([]);
  useEffect(() => {
    const meterRef = doc(db, "meters", location.state.id);
    getDoc(meterRef).then((d) => {
      setMeter({ ...d.data(), id: d.id });
    });

    const getTripsData = async () => {
      const tripsSnap = getDocs(
        query(
          collection(db, "meters", location.state.id, "trips"),
          limit(50),
          orderBy("creation_time", "desc"),
        ),
      );
      setTrips(
        (await tripsSnap).docs.map((doc) => ({ ...doc.data(), id: doc.id })),
      );
    };

    getTripsData();
  }, []);

  const renderTrips = (row) => {
    return (
      <TableRow
        key={row.id}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          "&.MuiTableRow-hover": { cursor: "pointer" },
        }}
        hover={true}
        onClick={() => nav(`/trip/${row.id}`, { state: { id: row.id } })}
      >
        <TableCell>{row.driver ? row.driver.id : ""}</TableCell>

        <TableCell>{conventDateTime(row.trip_start)}</TableCell>
        <TableCell>{conventDateTime(row.trip_end)}</TableCell>
        <TableCell>{row.payment_type}</TableCell>
        <TableCell align="right">{row.fare}</TableCell>
      </TableRow>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="admin-right">
        <h2>Meter {meter.id}</h2>
        {trips && (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="Trip table">
              <TableHead>
                <TableRow>
                  <TableCell>Driver</TableCell>
                  <TableCell>Trip Start</TableCell>
                  <TableCell>Trip End</TableCell>
                  <TableCell>Payment Type</TableCell>
                  <TableCell>Fare</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{trips.map((data) => renderTrips(data))}</TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </ThemeProvider>
  );
};

export default Meter;
