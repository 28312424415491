import {
  Grid,
  Typography,
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  TextareaAutosize,
  Divider,
  Button,
  CircularProgress,
} from "@mui/material";
import { isEmpty } from "lodash";

import React, { useState, useContext } from "react";
import { NumericFormat } from "react-number-format";
import Api from "../../utils/api";
import { TripContext } from "./trip";

const NumberFormatCustom = React.forwardRef(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
        prefix="$"
      />
    );
  },
);

const TripDrawer = (props) => {
  const api = new Api();

  const { tripDrawerProp, setTripDrawerProp } = useContext(TripContext);

  const [values, setValues] = useState({
    amount: "",
    reason: "",
    note: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    setLoading(true);
    api
      .tipsAdjustment(props.tripId, {
        ...values,
        amount: parseFloat(values.amount),
      })
      .then((resp) => {
        console.log(resp);
        setLoading(false);
        setTripDrawerProp({ open: false, reload: true });
      })
      .catch((err) => {
        console.error("error", err);
      });
  };

  return (
    <Box component="div" sx={{ m: 3 }} width={400}>
      <Typography marginLeft={2} variant="h6" component="div">
        Adjustment
      </Typography>
      <Divider />
      <Grid container spacing={2} mt={1}>
        <Grid
          item
          xs={4}
          alignItems="center"
          container
          justifyContent="flex-end"
        >
          <Typography sx={{ mx: 3, fontSize: 14 }}>Amount:</Typography>
        </Grid>
        <Grid item xs={8}>
          <TextField
            value={values.amount}
            onChange={handleChange}
            name="amount"
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            fullWidth
            margin="none"
            variant="outlined"
            size="small"
            disabled={loading}
            required
          />
        </Grid>

        <Grid
          item
          xs={4}
          alignItems="center"
          container
          justifyContent="flex-end"
        >
          <Typography sx={{ mx: 3, fontSize: 14 }}>Reason:</Typography>
        </Grid>
        <Grid item xs={8}>
          <FormControl variant="outlined" fullWidth size="small">
            <Select
              name="reason"
              value={values.reason}
              onChange={handleChange}
              disabled={loading}
            >
              <MenuItem value="OVERPAID">Overpaid</MenuItem>
              <MenuItem value="UNDERPAID">Underpaid</MenuItem>
              <MenuItem value="OTHER">Other</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={4} container justifyContent="flex-end">
          <Typography sx={{ mx: 3, fontSize: 14 }}>Note:</Typography>
        </Grid>
        <Grid item xs={8}>
          <TextareaAutosize
            name="note"
            value={values.note}
            onChange={handleChange}
            minRows={4}
            style={{ width: "100%", borderColor: "#ccc" }}
            disabled={loading}
          />
        </Grid>

        <Grid
          item
          xs={12}
          alignItems="center"
          container
          justifyContent="center"
        >
          <Box sx={{ m: 1, position: "relative" }}>
            <Button
              variant="contained"
              disabled={
                loading ||
                isEmpty(values.amount) ||
                isEmpty(values.reason) ||
                isEmpty(values.note)
              }
              onClick={handleSubmit}
            >
              Submit
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TripDrawer;
