import React, { useState } from "react";
import { navData } from "../../utils/NavData";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

import styles from "./side-nav.module.css";
import { NavLink, useLocation } from "react-router-dom";

const SideNav = () => {
  const [open, setopen] = useState(true);
  const location = useLocation();

  const toggleOpen = () => {
    setopen(!open);
  };

  return (
    <div className={open ? styles.sidenav : styles.sidenavClosed}>
      <button className={styles.menuBtn} onClick={toggleOpen}>
        {open ? (
          <KeyboardDoubleArrowLeftIcon />
        ) : (
          <KeyboardDoubleArrowRightIcon />
        )}
      </button>
      {navData.map((item) => {
        const currentRoute = (item.link == location.pathname+location.search);
        return (
          <NavLink key={item.id} className={[styles.sideitem, item.isSubMenu?styles.subMenu:"", currentRoute?styles.sideitemCurrent:""].join(" ")} to={item.link}>
            {item.icon}
            <span className={open ? styles.linkText : styles.linkTextClosed}>
              {item.text}
            </span>
          </NavLink>
        );
      })}
    </div>
  );
};

export default SideNav;
