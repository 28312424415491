import {
  Grid,
  Typography,
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  TextareaAutosize,
  Divider,
  Button,
  CircularProgress,
} from "@mui/material";
import { isEmpty } from "lodash";
import { onAuthStateChangedPromise } from "../../utils/firebase.js";
import React, { useEffect, useState, useContext } from "react";
import { NumericFormat } from "react-number-format";
import Api from "../../utils/api";
import RestApi from "../../utils/rest-api";
import { TransactionContext } from "./transaction";


const TransactionAddTagDrawer = (props) => {
  const restApi = new RestApi();
  const { addTagDrawerProp, setAddTagDrawerProp } = useContext(TransactionContext);
  const { modalProp, setModalProp } = useContext(TransactionContext);
  
  const [values, setValues] = useState({
    tag: "DISPUTE",
    note: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(addTagDrawerProp);
    setValues({...values, tag:addTagDrawerProp.tag, note:addTagDrawerProp.note});
  }, []);

  useEffect(() => {
    setValues({...values, tag:addTagDrawerProp.tag, note:addTagDrawerProp.note});
  }, [addTagDrawerProp]);

  const handleChange = (event) => {
    setAddTagDrawerProp({
      ...addTagDrawerProp,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    const user = await onAuthStateChangedPromise();
    restApi.tagTransactionByTxId(props.transactionId, {
      tags:[
        {
          tag: addTagDrawerProp.tag,
          note: addTagDrawerProp.note,  
          createdBy: user.email
        }        
      ]
      })
      .then((resp) => {
        console.log(resp);
        setLoading(false);
        setAddTagDrawerProp({ open: false, reload: true });
      })
      .catch((err) => {
        console.error("error", err);
        setModalProp({...modalProp, open:true, text:err.response.data.description});
        setLoading(false);        
        setAddTagDrawerProp({ ...addTagDrawerProp, open: false });        

      });
  };

  return (
    
    <Box component="div" sx={{ m: 3 }} width={400}>
      <Typography marginLeft={2} variant="h6" component="div">
        Add Tag
      </Typography>
      <Divider />
      <Grid container spacing={2} mt={1}>
        <Grid
          item
          xs={4}
          alignItems="center"
          container
          justifyContent="flex-end"
        >
          <Typography sx={{ mx: 3, fontSize: 14 }}>Tag:</Typography>
        </Grid>
        <Grid item xs={8}>
        <FormControl variant="outlined" fullWidth size="small">
            <Select
              name="tag"
              value={values.tag}
              onChange={handleChange}
              disabled={loading}
            >
              <MenuItem value="DISPUTE">Dispute</MenuItem>
              <MenuItem value="AMOUNT_MISMATCH">Amount Mismatch</MenuItem>
              <MenuItem value="UNABLE_TO_VOID">Unable to Void</MenuItem>
              <MenuItem value="UNABLE_TO_CAPTURE">Unable to Capture</MenuItem>
              <MenuItem value="OPEN_AUTH">Open Auth</MenuItem>
              <MenuItem value="PAYOUT_REJECTED">Payout Rejected</MenuItem>

            </Select>
          </FormControl>

        </Grid>

        <Grid
          item
          xs={4}
          alignItems="center"
          container
          justifyContent="flex-end"
        >
          <Typography sx={{ mx: 3, fontSize: 14 }}>Note:</Typography>
        </Grid>
        <Grid item xs={8}>
        <TextareaAutosize
            value={values.note}
            onChange={handleChange}
            name="note"
            minRows={4}
            style={{ width: "100%", borderColor: "#ccc" }}
            disabled={loading}
          />
        </Grid>

        {/* <Grid item xs={4} container justifyContent="flex-end">
          <Typography sx={{ mx: 3, fontSize: 14 }}>Note:</Typography>
        </Grid>
        <Grid item xs={8}>
          <TextareaAutosize
            name="note"
            value={values.note}
            onChange={handleChange}
            minRows={4}
            style={{ width: "100%", borderColor: "#ccc" }}
            disabled={loading}
          />
        </Grid> */}

        <Grid
          item
          xs={12}
          alignItems="center"
          container
          justifyContent="center"
        >
          <Box sx={{ m: 1, position: "relative" }}>
            <Button
              variant="contained"
              disabled={
                loading ||
                isEmpty(values.tag) ||
                isEmpty(values.note) 
                // || isEmpty(values.note)
              }
              onClick={handleSubmit}
            >
              Submit
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TransactionAddTagDrawer;
